import {getCookie} from "../utils/cookies";
let shiedKey = process.env.VUE_APP_API_MOULINETTE_KEY


export const shieldHeaders = {
    'Content-Type': 'application/json',
    'Authorization' : 'Bearer ' + shiedKey
};

export const tokenHeaders = {
    'Content-Type': 'application/json',
    'Authorization' : 'Bearer ' + shiedKey + ' Bearer ' +  getCookie('token')
};

